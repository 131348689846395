@use '@angular/material' as mat;
@use "./material-icons";
@use './dialog-styles';
@use 'taures.theme' as theme;
@use 'primeflex/primeflex';

@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";

@include mat.elevation-classes();
@include mat.app-background();

@include mat.all-component-themes(theme.$taures-theme);
@include mat.all-component-typographies(theme.$taures-typography-config);

h3 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.714em;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.143em;
}

//INPUTS
//form field inactive background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: #FAFAFA;
}

//form field label color
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
  --mdc-filled-text-field-label-text: rgba(18, 18, 18, 0.87);
}

//form field focus color
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  background-color: rgb(0, 0, 0);
  --mat-form-field-focus-state-layer-opacity: 0.06;
}

.mat-mdc-form-field:not(.ng-invalid):not(.ng-dirty).mat-focused .mdc-floating-label {
  color: mat.m2-get-color-from-palette(theme.$taures-primary) !important;
}

// BUTTONS
button.mdc-button {
  .mat-icon {
    line-height: 1;
  }
}

button.icon-button-21.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 21px;
  line-height: 1;
  padding: 9px;
  .mat-icon {
    line-height: 1;
    width: 21px;
    height: 21px;
    font-size: 21px;
  }
}

button.icon-button-20.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 20px;
  line-height: 1;
  padding: 10px;
  .mat-icon {
    line-height: 1;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
}

button.icon-button-18.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 18px;
  line-height: 1;
  padding: 11px;
  .mat-icon {
    line-height: 1;
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
}

.mdc-button:not(.mat-calendar-period-button), .mat-mdc-tab {
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-protected-button-label-text-tracking: 0;
  --mat-tab-header-label-text-tracking: 0;
  --mdc-text-button-label-text-tracking: 0;
  --mdc-outlined-button-label-text-tracking: 0;
}

.light-hover.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08 !important;
  background-color: #ffffff
}

//CHECKBOX
.mdc-checkbox {
  --mdc-checkbox-selected-focus-state-layer-color: rgba(0, 53, 114, 0.12);
  --mdc-checkbox-selected-focus-state-layer-opacity: 1;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 53, 114, 0.38);

  .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
    border: solid 1px rgba(0, 53, 114);
  }
}

mat-checkbox .mdc-label {
  font-size: 14px;
  line-height: 20px;
}

//RADIO
.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 20px;
}

mat-radio-button .mdc-form-field .mdc-label {
  padding-left: 7px;
  font-size: 14px;
  line-height: 24px;
}

