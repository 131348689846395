@use "taures.theme" as theme;
@use "@angular/material" as mat;

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: black;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
}

.mdc-dialog .mdc-dialog__title::before {
  height: unset;
}

.mat-mdc-dialog-title {
  --mat-dialog-headline-padding: 1.5rem 0.5rem 1.5rem 1rem;
  --mdc-dialog-subhead-line-height: 21px;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-size: 17.5px;
}

.mdc-dialog .mat-mdc-icon-button.mat-mdc-button-base { //this sizing has to be for all icons not only dialog, adjust during next update
  padding: 0.75rem;
  --mdc-icon-button-state-layer-size: 3rem;
  --mdc-icon-button-icon-size: 1.5rem;

  .mat-icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  --mat-dialog-content-padding: 0 1rem 1.5rem 1rem;
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  --mat-dialog-with-actions-content-padding: 0 1rem 1.5rem 1rem;
}

.mat-mdc-dialog-actions {
  --mat-dialog-actions-padding: 0 1.5rem 1.5rem 1.5rem;
}

.success-confirmation-popup {
  $success-color: mat.m2-get-color-from-palette(theme.$taures-success, 800);
  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-label-text-color: white;
    --mdc-filled-button-container-color: #{$success-color};
  }

  .mat-mdc-dialog-surface {
    border-left: 7px solid $success-color;
  }
}

.confirmation-dialog {
    --mdc-filled-button-label-text-color: white;
    --mdc-filled-button-container-color: theme.$taures-primary;
}

.information-confirmation-popup {
  $information-color: theme.$taures-information-color;

  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-label-text-color: white;
    --mdc-filled-button-container-color: #{$information-color};
  }

  .mat-mdc-dialog-surface {
    border-left: 7px solid $information-color;
  }
}


.danger-confirmation-popup {
  $danger-color: mat.m2-get-color-from-palette(theme.$taures-danger, 800);

  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-label-text-color: white;
    --mdc-filled-button-container-color: #{$danger-color};
  }

  .mat-mdc-dialog-surface {
    border-left: 7px solid $danger-color;
  }
}

.warning-confirmation-popup {
  $warning-color: mat.m2-get-color-from-palette(theme.$taures-warning, 800);

  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-label-text-color: theme.$taures-typo-black;
    --mdc-filled-button-container-color: #{$warning-color};
  }

  .mat-mdc-dialog-surface {
    border-left: 7px solid $warning-color;
  }
}

